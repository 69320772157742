
import { Component, Vue, Prop } from 'vue-property-decorator';
import { OfferModule } from '@/store/modules';

@Component
export default class CardListHeader extends Vue {
  @Prop() title!: string;
  @Prop() seeall!: boolean;

  offersCount = 0;

  created(): void {
    this.offersCount = OfferModule.getOffers.length;
  }

  goToOffers(): void {
    this.$router.push(`/offer?type=${this.title}`);
  }
}
